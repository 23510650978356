import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from "axios";

const ItemType = {
  STRIPE: "stripe",
};

const colorMapping = {
  "rød": "red",
  "blå": "blue",
  "grønn": "green",
  "gul" : "yellow"
  // add more colors as needed
};


const Stripe = ({ color, height, index, moveStripe }) => {
  const [, ref] = useDrag({
    type: ItemType.STRIPE,
    item: { index },
  });
  const [, drop] = useDrop({
    accept: ItemType.STRIPE,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveStripe(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const cssColor = colorMapping[color] || 'grey';  // default to grey if the color is not found

  // Define a scaling factor to adjust the visual height representation.
  // You can tweak this value as per your preference.
  const SCALING_FACTOR = 10;

  return (
    <div 
      ref={node => ref(drop(node))} 
      style={{
        background: cssColor, // use the mapped color here
        height: `${height * SCALING_FACTOR}px`, // using the height prop here
        padding: '10px',
        borderRadius: '4px',
        cursor: 'move'
      }}
    >
      {`${color}: ${height}`}
    </div>
  );
}


function App() {  


  const [stripes, setStripes] = useState([
    { color: "rød", height: 3 },
    { color: "blå", height: 5 },
    { color: "grønn", height: 1 },
    { color: "gul", height: 10 },
    { color: "grønn", height: 1 },
    { color: "blå", height: 5 },
    { color: "rød", height: 3 },
  ]);
  const [yarnDescription, setYarnDescription] = useState([]);
  const [rugWidth, setRugWidth] = useState(120);
  const [yarnWidth, setYarnWidth] = useState(1000);

  const moveStripe = (fromIndex, toIndex) => {
    const updatedStripes = [...stripes];
    const [movedItem] = updatedStripes.splice(fromIndex, 1);
    updatedStripes.splice(toIndex, 0, movedItem);
    setStripes(updatedStripes);
  };

  const stripesToText = (stripes) => {
    return stripes.map(stripe => `${stripe.color} ${stripe.height}`).join('\n');
  }

  const textToStripes = (text) => {
    return text
      .split('\n')
      .filter(line => line.trim() !== "") // Filter out empty lines
      .map(line => {
        const [color, heightString] = line.split(' ');
        const height = parseFloat(heightString);
        if (isNaN(height) || !color) {
          return null; // Or handle the error in another suitable way
        }
        return { color, height };
      })
      .filter(stripe => stripe !== null); // Remove any invalid entries
  }
  
  

  const [textAreaContent, setTextAreaContent] = useState('');

  // Function to convert the current stripes into a string representation.
  const updateTextAreaFromStripes = () => {
    setTextAreaContent(JSON.stringify(stripes, null, 2));
  };

  // Whenever the stripes state changes, update the textarea representation.
  useEffect(() => {
    updateTextAreaFromStripes();
  }, [stripes]);

  // Handle changes in the textarea
  const handleTextAreaChange = (e) => {
    const newValue = e.target.value;
    setTextAreaContent(newValue);
    try {
      const parsedValue = JSON.parse(newValue);
      if (Array.isArray(parsedValue) && parsedValue.every(item => item.color && typeof item.height === "number")) {
        setStripes(parsedValue);
      }
    } catch {
      // Invalid JSON or invalid structure, do not update stripes
    }
  };

  const handleSubmit = async () => {
    const rugDescription = stripes.map((stripe) => [stripe.color, stripe.height]);
    const data = {
      rug_description: rugDescription,
      rug_width: rugWidth,
      yarn_width: yarnWidth
    };
    try {
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.post(`${backendURL}/`, data);
      setYarnDescription(response.data.yarn_description);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <div style={{ width: "300px", margin: "0 auto" }}>
      <h2>Innstillinger</h2>
      <label>
        Vevbredde [cm]: <br></br>
        <input type="number" value={rugWidth} onChange={e => setRugWidth(e.target.value)} />
      </label>
      <br /><br></br>
      <label>
        Garnlengde fra en feed  [cm]:
        <input type="number" value={yarnWidth} onChange={e => setYarnWidth(e.target.value)} />
      </label>
      <br /><br />
 
      <h3>Tekstbeskrivelse av teppet</h3>
      <textarea 
        rows="10" 
        cols="30" 
        value={stripesToText(stripes)}
        onChange={(e) => setStripes(textToStripes(e.target.value))}
      />

      <DndProvider backend={HTML5Backend}>
        <h2>Dra og slipp-beskrivelse av teppet</h2>
        {stripes.map((stripe, index) => (
          <Stripe key={index} index={index} color={stripe.color} height={stripe.height} moveStripe={moveStripe} />
        ))}
      </DndProvider>
      <br></br>
      <button onClick={handleSubmit} className="button-submit">Få garnlengde</button>
      

      {yarnDescription.length > 0 && (
        <div>
          <h2>Garnlengde-beskrivelse</h2>
          <ul>
            {yarnDescription.map((item, index) => (
              <li key={index}>{item[0]}: {item[1]}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;
